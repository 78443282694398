<template>
  <QHeaderAdmin/>
  <div class="relative flex flex-col">
    <section class="px-4">
      <div class="mx-auto max-w-7xl flex flex-col">
        <QHeaderHr/>
        <NuxtPage/>
      </div>
    </section>
  </div>
  <QFooter/>
  <Toast group="app"/>
</template>

<script setup lang="ts">
import { ru } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'

const router = useRouter()
setDefaultOptions({ locale: ru })
</script>
