<template>
  <header ref="el">
    <div class="mx-auto max-w-7xl">
      <div class="py-9 text-base">
        <div class="flex">
          <div class="min-w-max">
            <NuxtLink to="/admin">
              <img src="~/assets/h-light-logo.svg" alt="Logo">
            </NuxtLink>
          </div>
          <div class="flex ml-[59px]">
            <div class="relative text-center px-14 mx-5">
              <NuxtLink v-if="isDev" to="/admin/users" class="inline-block my-2.5">
                {{ $t('Users') }}
              </NuxtLink>
              <div
                v-if="route.path.toString().startsWith('/admin/users')"
                class="flex h-[2px] w-full rounded-full brand-focus-bg left-0 bottom-0"/>
            </div>
            <div class="relative text-center px-14 mx-5">
              <NuxtLink to="/admin/items" class="inline-block my-2.5">
                {{ $t('Goods') }}
              </NuxtLink>
              <div
                v-if="route.path.toString().startsWith('/admin/items')"
                class="flex h-[2px] w-full rounded-full brand-focus-bg left-0 bottom-0"/>
            </div>
            <div class="relative text-center px-14 mx-5">
              <NuxtLink to="/admin" class="inline-block my-2.5 whitespace-nowrap">
                {{ $t('Categories') }}
              </NuxtLink>
              <div
                v-if="route.path.toString() === '/admin'"
                class="flex h-[2px] w-full rounded-full brand-focus-bg left-0 bottom-0"/>
            </div>
          </div>
          <div class="flex min-w-max items-center ml-auto">
            <div class="relative">
              <NuxtLink to="/account">
                <div>
                  <svg class="fill-current text-[#202020] hover:fill-[#CE4792]" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 0C27.9412 0 36 8.05893 36 18C36 27.9411 27.9412 36 18.0001 36C8.05882 36 0 27.9411 0 18C0 8.05893 8.05882 0 18.0001 0ZM18.0001 8.34072C22.0455 8.34072 25.3251 11.6203 25.3251 15.6658C25.3251 18.7472 23.4222 21.3839 20.7276 22.4658C24.9486 23.3837 28.3905 26.3887 29.9192 30.3458C33.1509 27.2251 35.1615 22.8475 35.1615 18C35.1615 8.5219 27.4781 0.838475 18.0001 0.838475C8.52194 0.838475 0.83848 8.5219 0.83848 18C0.83848 22.8477 2.84936 27.2252 6.08126 30.3461C7.60976 26.389 11.0516 23.3839 15.2724 22.4658C12.5778 21.384 10.6749 18.7472 10.6749 15.6658C10.6749 11.6203 13.9545 8.34072 17.9999 8.34072H18.0001ZM18.0001 9.15763C21.5945 9.15763 24.5082 12.0714 24.5082 15.6658C24.5082 19.2602 21.5945 22.1739 18.0001 22.1739C14.4057 22.1739 11.4919 19.2602 11.4919 15.6658C11.4919 12.0714 14.4057 9.15763 18.0001 9.15763ZM29.2689 30.9429C27.6222 26.3091 23.1988 22.9908 18.0001 22.9908C12.8012 22.9908 8.37806 26.3094 6.73153 30.9433C9.74648 33.5702 13.6874 35.1615 18.0001 35.1615C22.3131 35.1615 26.2541 33.5702 29.2689 30.9429Z"/>
                  </svg>
                </div>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const isDev = useIsDev()
const route = useRoute()
</script>
